.panel {
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
    margin-bottom: 20px;
}

.panel-heading {
    border-bottom: 1px solid transparent;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    padding: 10px 15px;
}

.panel-title {
    font-size: 16px;
    margin-bottom: 0;
    margin-top: 0;
}

.panel-body {
    padding: 15px;
}

.panel-default {
    border-color: #ddd;

    & > .panel-heading {
        background-color: #f5f5f5;
        border-color: #ddd;
        color: #333;
    }
}

.panel-success {
    border-color: #d6e9c6;

    & > .panel-heading {
        background-color: #dff0d8;
        border-color: #d6e9c6;
        color: #3c763d;
    }
}

.panel-info {
    border-color: #bce8f1;

    & > .panel-heading {
        background-color: #d9edf7;
        border-color: #bce8f1;
        color: #31708f;
    }
}

.panel-warning {
    border-color: #faebcc;

    & > .panel-heading {
        background-color: #fcf8e3;
        border-color: #faebcc;
        color: #8a6d3b;
    }
}

.panel-danger {
    border-color: #ebccd1;

    & > .panel-heading {
        background-color: #f2dede;
        border-color: #ebccd1;
        color: #a94442;
    }
}
