@import "bootstrap/scss/mixins/banner";
@include bsBanner("");

// Configuration
@import "bootstrap/scss/functions";

////////////////////////////////////////
// Custom variables
$blue: #337ab7;
$red: #d9534f;
$yellow: #f0ad4e;
$green: #5cb85c;
$cyan: #5bc0de;
//$indigo: #6610f2 !default;
//$purple: #6f42c1 !default;
//$pink: #d63384 !default;
//$orange: #fd7e14 !default;
////////////////////////////////////////

@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

////////////////////////////////////////
// Custom maps
// $primary: $blue;
$secondary: $gray-500;
////////////////////////////////////////

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

// Layout & components
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar";
@import "bootstrap/scss/card";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas";
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

////////////////////////////////////////
// 新たにクラスを追加して上書きしないとうまくできないカスタマイズ

// ボタンの文字色を白にする
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    @if $color != "light" and $color != "dark" {
      --#{$prefix}btn-color: #fff;

      --#{$prefix}btn-hover-color: #fff;
      --#{$prefix}btn-hover-bg: #{shade-color($value, $btn-hover-bg-shade-amount)};
      --#{$prefix}btn-hover-border-color: #{shade-color($value, $btn-hover-border-shade-amount)};

      --#{$prefix}btn-active-color: #fff;
      --#{$prefix}btn-active-bg: #{shade-color($value, $btn-active-bg-shade-amount)};
      --#{$prefix}btn-active-border-color: #{shade-color($value, $btn-active-border-shade-amount)};

      --#{$prefix}btn-disabled-color: #fff;
    }
  }
}
// ボタンホバー時のアンダーラインなし
.btn:hover {
  text-decoration: none;
}
