@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 100;
    src: url(../font/NotoSansJP-Thin.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 200;
    src: url(../font/NotoSansJP-Light.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 300;
    src: url(../font/NotoSansJP-DemiLight.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: url(../font/NotoSansJP-Regular.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 500;
    src: url(../font/NotoSansJP-Medium.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 700;
    src: url(../font/NotoSansJP-Bold.woff) format("woff");
}

@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 900;
    src: url(../font/NotoSansJP-Black.woff) format("woff");
}

/** 標準の追加カスタマイズ **/
html {
    font-size: 14px;
}

/*
a:not(.btn),
.btn-link {
    text-decoration: none;
}
a:not(.btn):hover,
.btn-link:hover {
    text-decoration: underline;
}
*/


body {
    color: #444;
    font-family: 'Noto Sans Japanese', sans-serif;
}

img {
    height: auto;
    max-width: 100%;
}

a {
    color: #ef8688;
    text-decoration: underline;
}

a:hover, a:active, a:focus {
    color: #ef8688;
    text-decoration: underline;
}

.nav-link {
    color: #ef8688;
}

@media (max-width: 767px) {
    .full-width-xs {
        margin-right: calc(50% - 50vw);
        margin-left: calc(50% - 50vw);
    }
}
@media (max-width: 991px) {
    .full-width-sm {
        margin-right: calc(50% - 50vw);
        margin-left: calc(50% - 50vw);
    }
}
@media (max-width: 1200px) {
    .full-width-md {
        margin-right: calc(50% - 50vw);
        margin-left: calc(50% - 50vw);
    }
}

.children-spaced > * + * {
    margin-top: 25px;
}

/* site specific */
.btn {
    text-decoration: none;
}

.btn-primary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #f57d80 !important;
    border: none !important;
    border-bottom: 4px solid #e6686b !important;
    color: #fff !important;
    font-weight: bold;
    font-size: 18px;
    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.2);
    text-decoration: none !important;
}

@media (min-width: 992px) {
    .btn-primary {
        font-size: 24px;
    }
}

.btn-primary::before {
    margin-right: 10px;
    margin-left: -20px;
    display: inline-block;
    content: '';
    height: 17px;
    width: 10px;
    background-image: url(../img/arrow-btn-primary.png);
    background-repeat: no-repeat;
    background-size: contain;
}

.btn-secondary {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #fff !important;
    color: #7b7b7b !important;
    border: 1px solid #dedede !important;
    font-weight: normal;
    font-size: 15px;
    text-decoration: none !important;
}

@media (min-width: 992px) {
    .btn-secondary {
        font-size: 20px;
    }
}

.btn-secondary::before {
    margin-right: 10px;
    margin-left: -17px;
    display: inline-block;
    content: '';
    height: 12px;
    width: 7px;
    background-image: url(../img/arrow-btn-secondary.png);
    background-repeat: no-repeat;
    background-size: contain;
}

/* dom specific */
.l-contents__aside {
    margin-top: 25px;
    padding: 0 15px;
}

@media (min-width: 992px) {
    .l-contents__aside {
        margin-top: 0;
    }
}

.l-footer {
    background-color: #cce19b;
}

@media (min-width: 992px) {
    .l-footer {
        margin-top: 56px;
    }
}

.l-header {
    background-image: url(../img/anniversary19.png), url(../img/header-back.png);

    /*background-image: url(../img/anniversary16.png), url(../img/header-back.png);*/
    background-repeat: no-repeat, repeat;
    background-size: 150px, auto;
    background-position: 52% 0, 0 0;
    overflow: hidden;
    padding: 15px 0 0;
}

@media (min-width: 768px) {
    .l-header {
        background-size: 200px, auto;
    }
}

@media (min-width: 992px) {
    .l-header {
        margin-bottom: 23px;
        background-image: url(../img/anniversary19-pc.png), url(../img/header-back.png);
        background-size: 356px, auto;
        background-position: 50% 20%, 0 0;
        border-bottom: 1px solid #dedede;
        padding: 30px 0 0;
    }
}

@media (min-width: 1200px) {
    .l-header {
        background-size: 456px, auto;
    }
}

.l-header .row {
    margin-right: -5px;
    margin-left: -5px;
}

.l-header .row [class^="col"],
.l-header .row [class*="col^"] {
    padding-right: 5px;
    padding-left: 5px;
}

.l-header__site-branding {
    color: #fff;
    font-size: 10px;
    white-space: nowrap;
}

@media (min-width: 992px) {
    .l-header__site-branding {
        font-size: 18px;
    }
}

.l-header__site-branding img {
    width: 130px;
}

@media (min-width: 576px) {
    .l-header__site-branding img {
        width: 200px;
    }
}

@media (min-width: 992px) {
    .l-header__site-branding img {
        width: 374px;
    }
}

.l-header__anniversary img {
    width: 122px;
}

.l-header__controls .btn-primary {
    padding: .5em .75em .5em 1.5em;
}

.c-copyright {
    background-color: #b2c780;
    color: #fff;
    font-size: 10px;
    padding: .5em 0;
    text-align: center;
}

@media (min-width: 992px) {
    .c-copyright {
        font-size: 14px;
    }
}

.l-footer .c-copyright {
    margin-top: 24px;
}

@media (min-width: 992px) {
    .l-footer .c-copyright {
        margin-top: 0;
    }
}

.c-main-visual {
    position: relative;
}

.c-main-visual img {
    width: 100%;
}

.c-main-visual .btn-primary {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 8vw;
    padding-right: 3em;
    padding-left: 3em;
    white-space: nowrap;
}

@media (min-width: 576px) {
    .c-main-visual .btn-primary {
        font-size: 30px;
    }
}

@media (min-width: 768px) {
    .c-main-visual .btn-primary {
        font-size: 36px;
    }
}

@media (min-width: 992px) {
    .c-main-visual .btn-primary {
        bottom: 35px;
    }
}

@media (min-width: 1200px) {
    .c-main-visual .btn-primary {
        bottom: 40px;
    }
}

.c-mobile-controls {
    list-style: none;
    text-align: center;

    & a, & button {
        margin: 0;
        background-color: transparent;
        border: none;
        color: #fff;
        padding: 0;
    }

    & a:hover, & a:active, & a:focus, & button:hover, & button:active, & button:focus {
        text-decoration: none;
    }
}



.c-mobile-controls__icon {
    display: flex;
    align-items: center;
    height: 30px;

    & img {
        margin: 0 auto;
    }
}

.c-mobile-controls__icon--setting {
    & img {
        width: 30px;
    }
}

.c-mobile-controls__icon--menu {
    & img {
        width: 25px;
    }
}

.c-mobile-controls__label {
    display: block;
    font-size: 9px;
    white-space: nowrap;
}

.c-site-branding {
    margin: 0;
    padding: 0;
    font-size: 100%;
}

.c-wiki-list {
    list-style: none;
    margin: 0;
    padding: 0;

    @media (min-width: 992px) {
        border-bottom: 1px dotted #bfbfbf;
    }
}

.c-wiki-list__item {
    margin: 0;
    font-size: 13px;
    padding: 1em 0;

    @media (min-width: 992px) {
        font-size: 16px;
        padding: 1em 15px;
    }

    &:not(:first-child) {
        border-top: 1px dotted #bfbfbf;
    }
}

.c-wiki-list__item-date {
    display: block;
    margin-right: 1.5em;
    color: #a2a2a2;
    font-size: 9px;
    padding-top: 2px;

    @media (min-width: 992px) {
        float: left;
        font-size: 18px;
    }
}

.c-wiki-list__item-body {
    overflow: hidden;
}

.c-wiki-list a {
    color: #444;
    text-decoration: none;

    &:hover, &:active, &:focus {
        text-decoration: underline;
    }
}


.p-contact {
    background-color: #deeffc;
    background-image: url(../img/purpose.png);
    border-radius: 10px;
    padding: 18px;
}

.p-contact__title {
    margin: 0 0 14px;
    border-bottom: 1px solid #51738f;
    color: #51738f;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 0 0 14px;
}

.p-contact__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-contact__item {
    margin: 0;
    padding: 0;

    &:not(:first-child) {
        margin-top: 10px;
    }

    & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 5px;
        color: #51738f;
        font-size: 13px;
        font-weight: bold;
        padding: 10px;
        text-align: center;
        text-decoration: none;

        @media (min-width: 992px) {
            font-size: 15px;
        }

        &::before {
            margin-right: 10px;
            margin-left: -16px;
            display: inline-block;
            content: '';
            height: 12px;
            width: 6px;
            background-image: url(../img/arrow-contact.png);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

.p-entry__header {
    margin-bottom: 25px;

    & h1 {
        margin-top: 0;
        background-color: #eaeaea;
        background-image: url(../img/h2.png);
        color: #6a6a6a;
        font-size: 20px;
        font-weight: bold;
        padding: 15px;
        text-align: center;
    }
}

.p-entry__content {
    color: #444;
    font-size: 13px;

    @media (min-width: 992px) {
        font-size: 16px;
    }

    & > h2 {
        background-color: #f6f6f6;
        font-size: 16px;
        margin-bottom: 10px;
        margin-top: 20px;
        padding: 15px 10px;

        @media (min-width: 992px) {
            font-size: 16px;
        }
    }
}


.p-footer-nav {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    @media (min-width: 992px) {
        padding: 24px 0;
    }
}

.p-footer-nav__item {
    display: inline-block;
    margin: 0;
    font-size: 10px;
    line-height: 1;
    padding: 0 1em;

    &:not(:first-child) {
        border-left: 1px solid #6c803d;
    }

    @media (min-width: 992px) {
        font-size: 16px;
    }
}

.p-footer-nav a {
    color: #6c803d;
    text-decoration: none;

    &:hover, &:active, &:focus {
        text-decoration: underline;
    }
}


.p-for-beginner {
    background-color: #fde6e7;
    background-image: url(../img/purpose.png);
    border-radius: 10px;
    padding: 18px;
}

.p-for-beginner__title {
    margin: 0 0 14px;
    border-bottom: 1px solid #9f4548;
    color: #9f4548;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 0 0 14px;
}

.p-for-beginner__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-for-beginner__item {
    margin: 0;
    padding: 0;

    &:not(:first-child) {
        margin-top: 10px;
    }

    & > a {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-radius: 5px;
        color: #9f4548;
        font-size: 13px;
        font-weight: bold;
        padding: 10px;
        text-align: center;
        text-decoration: none;

        @media (min-width: 992px) {
            font-size: 15px;
        }

        &::before {
            margin-right: 10px;
            margin-left: -16px;
            display: inline-block;
            content: '';
            height: 12px;
            width: 6px;
            background-image: url(../img/arrow-for-beginner.png);
            background-repeat: no-repeat;
            background-size: contain;
        }
    }
}

.p-global-nav {
    background-color: #fff;
    border-top: 2px solid #ed878a;
    overflow: auto;
}

.p-global-nav__list {
    display: flex;
    flex-direction: row;
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-global-nav__item {
    flex: 1 1 auto;
    margin: 0;
    font-size: 13px;
    padding: 0;
    text-align: center;

    @media (min-width: 992px) {
        flex: 0 1 auto;
        font-size: 16px;
    }

    & > a {
        position: relative;
        display: block;
        color: #444;
        font-weight: bold;
        text-decoration: none;
        padding: 1em;
        white-space: nowrap;

        @media (min-width: 992px) {
            padding: 1em 2em;
        }
    }

    &:not(:first-child) > a::before {
        position: absolute;
        top: 1em;
        bottom: 1em;
        left: 0;
        content: '';
        display: block;
        width: 1px;
        background-color: #eee;
    }
}

.l-header .p-global-nav {
    margin-top: 15px;

    @media (min-width: 992px) {
        margin-top: 25px;
    }
}

.p-hot-wiki__title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cce19b;
    background-image: url(../img/h2.png);
    color: #566b1c;
    font-size: 20px;
    font-weight: bold;
    padding: .5em 1em;
    text-align: center;

    @media (min-width: 992px) {
        font-size: 30px;
        padding: .25em 1em;
    }

    &::before {
        margin-right: 10px;
        margin-left: -70px;
        display: inline-block;
        content: '';
        height: 34px;
        width: 33px;
        background-image: url(../img/icon-hot-wiki.png);
        background-repeat: no-repeat;
        background-size: contain;

        @media (min-width: 992px) {
            height: 61px;
            width: 60px;
        }
    }
}

.p-information__title {
    background-color: #aed3f2;
    background-image: url(../img/h2.png);
    color: #46667b;
    font-size: 20px;
    font-weight: bold;
    padding: .75em 1em;
    text-align: center;
}

.p-information__body {
    font-size: 13px;
}

.p-join {
    background-color: #cce19b;
    padding: 24px;

    @media (min-width: 992px) {
        background-color: transparent;
        padding: 0;
    }
}

.p-join__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-join__item {
    margin: 0;
    padding: 0;

    &:not(:first-child) {
        margin-top: 13px;
    }
}

.p-recent-wiki__title {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cce19b;
    background-image: url(../img/h2.png);
    color: #566b1c;
    font-size: 20px;
    font-weight: bold;
    padding: .5em 1em;
    text-align: center;

    @media (min-width: 992px) {
        font-size: 30px;
        padding: .25em 1em;
    }

    &::before {
        margin-right: 10px;
        margin-left: -63px;
        display: inline-block;
        content: '';
        height: 35px;
        width: 30px;
        background-image: url(../img/icon-recent-wiki.png);
        background-repeat: no-repeat;
        background-size: contain;

        @media (min-width: 992px) {
            height: 62px;
            width: 53px;
        }
    }
}

.p-sp-nav {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    overflow: auto;
    background-color: #cce19b;
    border: 10px solid #fff;
    padding: 10px;
    transition: opacity .2s;

    &[aria-hidden="true"] {
        opacity: 0;
        visibility: hidden;
    }

    &[aria-hidden="false"] {
        opacity: 1;
        visibility: visible;
    }
}


.p-sp-nav__title {
    background-color: #acca64;
    color: #fff;
    font-size: 20px;
    font-weight: bold;
    padding: 10px 15px;
    overflow: hidden;

    & .p-sp-nav__btn-close {
        float: right;
    }
}

.p-sp-nav__register {
    padding: 15px;
}

.p-sp-nav__list {
    list-style: none;
    margin: 0;
    padding: 0;
}

.p-sp-nav__list + .p-sp-nav__list {
    margin-top: 10px;
}

.p-sp-nav__item {
    margin: 1px 0 0;
    padding: 0;

    & > a {
        display: block;
        background-color: #fff;
        color: #444;
        font-size: 15px;
        text-decoration: none;
        padding: 15px;
    }
}

/* Google Search Form */
.google-search-form {
    padding: 0 15px;

    & h3 {
        background-color: #aed3f2;
        color: #46667b;
        font-size: 20px;
        font-weight: bold;
        padding: .75em 1em;
        text-align: center;
    }
}
