a.logo-link {
    &:link, &:visited, &:hover {
        color: inherit;
        text-decoration: none;
    }
}

.btn-primary-org {
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;

    &:hover {
        color: #fff;
        background-color: #0069d9;
        border-color: #0062cc;
    }

    &:focus, &.focus {
        box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
    }
}

/* -------- 箇条書き --------- */

.l-contents__main {
    & li {
        margin: 4px auto;
    }

    & ol {
        list-style-type: decimal;

        & ol {
            margin: 8px auto;
            counter-reset: subsection;
            list-style-type: none;

            & > li {
                position: relative;

                &:before {
                    position: absolute;
                    left: -40px;
                    width: 38px;
                    text-align: right;
                    counter-increment: subsection;
                    content: "(" counter(subsection) ") ";
                }
            }
        }
    }
}

.l-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

.wrap {
  flex: 1 0 auto;
}

.v-center {
  display: flex;
  align-items: center;
}
