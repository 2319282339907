/* Base class */
.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .05);

    & blockquote {
        border-color: #ddd;
        border-color: rgba(0, 0, 0, .15);
    }

    & .form-check-input {
        border-color: #888;
    }
    & .form-control,
    & .input-group-text {
        border-color: #bbb;
    }
}

/*!* Sizes *!*/
/*.well-lg {*/
/*   padding: 24px;*/
/*   border-radius: @border-radius-large;*/
/*}*/
/*.well-sm {*/
/*    padding: 9px;*/
/*    border-radius: @border-radius-small;*/
/*}*/
