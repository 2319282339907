dl.dl-horizontal {
    & > dt {
        float: left;
        width: 160px;
        overflow: hidden;
        clear: left;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    & > dd {
        margin-left: 180px;
    }
}