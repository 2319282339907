.t-btn-primary {
    font-size: 24px!important;
    white-space: normal;
    flex-wrap: wrap;
}

.t-btn-primary-feature {
    font-size: 24px!important;
}

.l-header__controls {
    padding: 0 0 0 5px;
}

@media (min-width: 1200px) {
    .t-btn-primary {
        font-size: 24px!important;
    }
    .t-btn-primary-feature {
        font-size: 36px!important;
    }
    .t-btn-primary-registration {
        font-size: 20px!important;
    }
    .l-header__controls {
        padding: 30px 0 0 20px;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .t-btn-primary {
        font-size: 24px!important;
    }
    .t-btn-primary-feature {
        font-size: 36px!important;
    }
    .t-btn-primary-registration {
        font-size: 20px!important;
    }
    .l-header__controls {
        padding: 30px 0 0 20px;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .c-main-visual .t-btn-primary {
        font-size: 36px!important;
    }
    .t-btn-primary-feature {
        font-size: 36px!important;
    }
    .t-btn-primary-registration {
        font-size: 15px!important;
    }
    .l-header__controls {
        padding: 14px 0 0 20px;
    }
    .l-header {
        background-size: 180px, auto;
    }
}

@media (min-width: 576px) and (max-width: 767px){
    .t-btn-primary-feature {
        font-size: 24px!important;
    }
    .t-btn-primary-registration {
        font-size: 15px!important;
    }
    .l-header__controls {
        padding-top:10px;
    }
}
@media (min-width: 320px) and (max-width: 575px) {
  .t-btn-primary {
    font-size: 18px !important;
    white-space: normal;
  }
  .t-btn-primary-feature {
    font-size: 18px !important;
  }
}

/**
 * BS バージョンジャップ対応
 * レスポンシブのブレークポイント仕様を BS3 に寄せてレイアウト崩れを防ぐ
 */
@media (min-width: 576px) and (max-width: 767px) {
    .container-md, .container-sm, .container {
        max-width: none;
    }
}
@media (min-width: 1400px) {
    .container-xl, .container-lg, .container-md, .container-sm, .container {
        max-width: 1140px;
    }
}

.btn.btn-lg.btn-primary {
    font-size: 18px;
}

.btn.btn-primary {
    font-size: inherit;
}

.bt-sm.btn-primary {
    font-size: 12px;
}

.bt-xs.btn-primary {
    font-size: 12px;
}

.btn-primary {
  padding-left: 30px;
  padding-right: 20px;
}

.t-btn-primary-nav-registration {
    font-size:18px!important;
}

button.btn.btn-primary {
  border-bottom:0!important;
}

h2.p-information__title {
  margin-top:0;
}