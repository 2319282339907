pre {
    color: #333;
    display: block;
    font-size: 13px;
    line-height: 1.42857143;
    margin: 0 0 10px;
    padding: 9.5px;
    word-break: break-all;
    word-wrap: break-word;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 4px;

    & code {
        background-color: transparent;
        border-radius: 0;
        color: inherit;
        font-size: inherit;
        padding: 0;
        white-space: pre-wrap;
    }
}
code {
    background-color: #f9f2f4;
    border-radius: 4px;
    color: #c7254e;
}
code, kbd {
    font-size: 90%;
    padding: 2px 4px;
}
code, kbd, pre, samp {
    font-family: Menlo, Monaco, Consolas, Courier New, monospace;
}
