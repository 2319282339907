@media(min-width: 992px) {
    .grid-container {
        display: grid;
        grid-column-gap: 15px;
        grid-template-columns: 2fr 1fr;
        grid-template-rows: min-content min-content 1fr;


        /* standard left */
        & .l-contents__main {
            grid-row: 1 / span 3;
            grid-column: 1;
        }

        /* right */
        & .google-search-form {
            grid-row: 1 / span 1;
            grid-column: 2;
        }
        & .google-search-form h3 {
            margin-top: 0;
        }

        & .l-contents__aside {
            grid-row: 2 / span 2;
            grid-column: 2;
        }

        /* left for home */
        & .c-main-visual {
            grid-row: 1 / span 2;
            grid-column: 1;
        }
        & .l-contents__main-top {
            grid-row: 3;
            grid-column: 1;
        }
    }
}
