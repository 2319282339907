/* dl-inline の sm ブレークでやっていたことを detail-view */

@media (max-width: 576px) {
    table.detail-view-responsive {
        display: block;
        padding: 0.5rem 0;

        & > :not(caption),
        & > :not(caption) > *,
        & > :not(caption) > * > * {
            display: block;
        }

        & > :not(caption) > tr > th {
            border-width: 0;
            padding-bottom: 0;
        }

        & > :not(caption) > tr > td {
            padding-top: 0;
        }

        &.table-bordered > :not(caption) > tr {
            margin-bottom: -1px;
        }

        &:not(.table-striped) > :not(caption) > * > *,
        &:not(.table-bordered) > :not(caption) > * > * {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
